<div style="display: none;">
  <div class="overlay-container" #Overlay tabindex="2" >
    <p class="mat-subtitle-1">{{ getName() }}</p>
    <button mat-flat-button [cdkCopyToClipboard]="getStreetViewUrl()">
      <mat-icon>content_copy</mat-icon>Location
    </button>
    <button mat-flat-button (click)="goToGoogleMaps()">
      <mat-icon>open_in_new</mat-icon> Map
    </button>
  </div>
</div>

