import {Component, ElementRef, ViewChild} from '@angular/core';
import {Feature} from "ol";
import {transform} from "ol/proj";
import {Point} from "ol/geom";
import {MatButton, MatFabButton, MatMiniFabButton} from "@angular/material/button";
import {CdkCopyToClipboard} from "@angular/cdk/clipboard";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-map-overlay',
  standalone: true,
  imports: [
    MatButton,
    CdkCopyToClipboard,
    MatFabButton,
    MatIcon,
    MatMiniFabButton
  ],
  templateUrl: './map-overlay.component.html',
  styleUrl: './map-overlay.component.scss'
})
export class MapOverlayComponent {
  @ViewChild('Overlay', {static: true}) elementRef: ElementRef;
  location: Feature;

  getStreetViewUrl() {
    if (!this.location) {
      return ""
    }
    let center = transform(
      (this.location.getGeometry() as Point).getCoordinates(),
      'EPSG:3857',
      'EPSG:4326'
    );
    //"https://www.google.com/maps/search/?api=1&query=58.698017,-152.522067"
    let url = "https://www.google.com/maps/search/"
    url = url + "?api=1&"
    url = url + "query=" + center[1] + "," + center[0];
    return url;
  }

  goToGoogleMaps() {
    window.location.href = this.getStreetViewUrl();
  }

  getName() {
    return this.location ? this.location.getProperties()["name"] : "";
  }
}
