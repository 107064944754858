import {Feature} from "ol";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import {AreaFlag} from "./map-area.model";


export function area_style(feature: Feature) {
  const flags = feature.getProperties()['flags'] as AreaFlag[]
  if (flags.includes(AreaFlag.CROP)) {
    return new Style({
      stroke: new Stroke({color: '#007ed6', width: 3})
    })
  } else if (flags.includes(AreaFlag.FILL)) {
    return [
      new Style({
        stroke: new Stroke({color: '#007ed6', width: 3}),
        fill: new Fill({color: 'rgba(0,126,214,0.3)'})
      }),
      new Style({
        text: new Text({
          text: feature.getProperties()["name"],
          stroke: new Stroke({color: "#007ed6", width: 3}),
          fill: new Fill({color: 'white'}),
          font: 'bold 1rem sans-serif',
          overflow: false
        })
      })
    ]
  } else if (flags.includes(AreaFlag.FOCUS)) {
    return new Style({
      stroke: new Stroke({color: '#da5353', width: 3})
    })
  } else {
    return null;
  }
}

export function area_hover_style(feature: Feature) {
  return new Style({
    fill: new Fill({
      color: '#007ed6',
    }),
    stroke: new Stroke({
      color: '#007ed6',
      width: 2,
    }),
    text: new Text({
      text: feature.getProperties()["name"],
      stroke: new Stroke({color: "#007ed6", width: 3}),
      fill: new Fill({color: 'white'}),
      font: "bold 1.5rem sans-serif",
      overflow: true
    })
  })
}


export function loc_style(feature: Feature) {
  const labeled = feature.getProperties()["labeled"];
  if (labeled) {
    return [
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          scale: 0.05,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: "assets/images/icons/marker_green.svg"
        })
      }),
      new Style({
        image: new Icon({
          anchor: [0.5, 1.95],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: locationTypeToIcon(feature.getProperties()['type'])
        })
      })
    ]
  } else {
    return [
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          scale: 0.05,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: "assets/images/icons/marker.svg"
        })
      }),
      new Style({
        image: new Icon({
          anchor: [0.5, 1.95],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: locationTypeToIcon(feature.getProperties()['type'])
        })
      })
    ]
  }
}

export function loc_hover_style(feature: Feature) {
  const labeled = feature.getProperties()["labeled"];
  if (labeled) {
    return [
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          scale: 0.05,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: "assets/images/icons/marker_green.svg"
        })
      }),
      new Style({
        image: new Icon({
          anchor: [0.5, 1.95],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: locationTypeToIcon(feature.getProperties()['type'])
        }),
        text: new Text({
          text: feature.getProperties()["name"],
          stroke: new Stroke({color: "white", width: 5}),
          fill: new Fill({color: '#6db33f'}),
          font: 'bold 1rem sans-serif',
          overflow: false
        })
      })
    ]
  } else {
    return [
      new Style({
        image: new Icon({
          anchor: [0.5, 1],
          scale: 0.05,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: "assets/images/icons/marker.svg"
        })
      }),
      new Style({
        image: new Icon({
          anchor: [0.5, 1.95],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: locationTypeToIcon(feature.getProperties()['type'])
        }),
        text: new Text({
          text: feature.getProperties()["name"],
          stroke: new Stroke({color: "white", width: 5}),
          fill: new Fill({color: '#007ed6'}),
          font: 'bold 1rem sans-serif',
          overflow: false
        })
      })
    ]
  }
}

export function locationTypeToIcon(type: string) {
  let base = "assets/images/icons/"
  switch (type) {
    case "ZOO":
      return base + "pets.svg";
    case "PANORAMA":
      return base + "panorama.svg";
    case "AMUSEMENT":
    case "THEME_PARK":
    case "WATER_PARK":
      return base + "attractions.svg";
    case "ROAD":
      return base + "road.svg";
    case "ROCK":
      return base + "rock.svg";
    case "CANYON":
      return base + "canyon.svg"
    case "MUSEUM":
      return base + "museum.svg";
    case "WATERFALL":
      return base + "waterfall.svg";
    case "CAVE":
      return base + "cave.svg";
    case "LAKE":
    case "RIVER":
      return base + "water.svg";
    case "STREET":
      return base + "walk.svg";
    case "SQUARE":
      return base + "square.svg";
    case "TOWER":
    case "PALACE":
    case "CASTLE":
    case "BASTION":
    case "FORTRESS":
    case "HISTORICAL_BUILDING":
      return base + "castle.svg";
    case "CHURCH":
    case "CATHEDRAL":
    case "MONASTERY":
      return base + "church.svg";
    case "MINI_TOWN":
    case "CITY":
      return base + "city.svg";
    case "PARK":
      return base + "park.svg";
    case "VOLCANO":
      return base + "volcano.svg";
    case "BREWERY":
      return base + "factory.svg";
    default:
      return base + "question_mark.svg";
  }
}
