import {afterNextRender, Component, ElementRef, Input, NgZone, ViewChild} from '@angular/core';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import {OSM} from "ol/source";
import {Feature, MapBrowserEvent, Overlay, View} from "ol";
import {MapService} from "./map.service";
import {AreaFlag, MapArea} from "./map-area.model";
import {area_hover_style, area_style, loc_style, loc_hover_style} from "./map-styles";
import Crop from "ol-ext/filter/Crop";
import {buffer} from "ol/extent";
import {MapLocation} from "./map-location.model";
import {MapOverlayComponent} from "./map-overlay/map-overlay.component";
import {BehaviorSubject, debounceTime, Subject} from "rxjs";


@Component({
  selector: 'app-map',
  standalone: true,
  imports: [MapOverlayComponent],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent {
  @ViewChild('MAP') mapHtmlTagRef: ElementRef;
  @ViewChild('LOC_OVERLAY') locOverlayComp: MapOverlayComponent;

  private areasLayer = new VectorLayer({source: new VectorSource(), style: area_style});
  private locationsLayer = new VectorLayer({source: new VectorSource(), style: loc_style});
  private osm = new TileLayer({source: new OSM()});

  private map: Map;
  private locOverlay: Overlay;
  private hovered: Feature = null;

  private zoomIn: Subject<string> = new Subject<string>()

  constructor(private service: MapService, private ngZone: NgZone) {
    afterNextRender(() => {
      this.locOverlay = new Overlay({
        element: this.locOverlayComp.elementRef.nativeElement,
        autoPan: {animation: {duration: 250}}
      })
      this.map = new Map({
        layers: [this.osm, this.areasLayer, this.locationsLayer],
        target: this.mapHtmlTagRef.nativeElement,
        overlays: [this.locOverlay],
        controls: []
      });
      this.map.on("pointermove", (e) => this.handlePointerMove(e));
      this.map.on("singleclick", (e) => this.ngZone.run(() => this.handleSingleClick(e)));
      this.findAndSetView()
    })
    this.zoomIn.pipe(debounceTime(500)).subscribe((value)=>this.zoomInToFeature())
  }

  @Input()
  set areas(areas: MapArea[]) {
    this.areasLayer.getSource().clear()
    areas.forEach(a => {
      const f = this.service.mapAreaToFeature(a)
      this.areasLayer.getSource().addFeature(f)
    })
    this.zoomIn.next("1")
  }

  @Input()
  set locations(locations: MapLocation[]) {
    this.locationsLayer.getSource().clear()
    locations.forEach(l => {
      const f = this.service.mapLocationToFeature(l)
      this.locationsLayer.getSource().addFeature(f)
    })
    this.zoomIn.next("1")
  }

  handlePointerMove(event: MapBrowserEvent<UIEvent>): void {
    this.showCursor(event);
  }

  handleSingleClick(event: MapBrowserEvent<UIEvent>): void {
    this.locOverlay.setPosition(undefined);
    this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature, layer) => {
      if (layer == this.areasLayer) {
        this.service.areaIdFocusEmitter.emit(feature.getProperties()['id'])
        return true;
      } else if (layer == this.locationsLayer) {
        if (feature.getProperties()['labeled']) {
          this.locOverlayComp.location = feature;
          this.locOverlay.setPosition(feature.getGeometry()["flatCoordinates"])
        } else {
          this.service.locationIdFocusEmitter.emit(feature.getProperties()['id'])
        }
        return true
      }
      return false
    })
  }

  private showCursor(event: MapBrowserEvent<UIEvent>) {
    if (this.hovered != null) {
      this.hovered.setStyle(undefined);
      this.hovered = null;
    }
    let hit = this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature, layer) => {
      if (layer == this.areasLayer && feature.getProperties()['flags']) {
        const flags = (feature.getProperties()['flags'] as AreaFlag[])
        if (flags.includes(AreaFlag.FILL)) {
          this.hovered = feature;
          this.hovered.setStyle(area_hover_style);
          return true;
        }
      } else if (layer == this.locationsLayer) {
        this.hovered = feature;
        this.hovered.setStyle(loc_hover_style);
        return true;
      }
      return false;
    });

    if (hit) {
      this.map.getTargetElement().style.cursor = 'pointer';
    } else {
      this.map.getTargetElement().style.cursor = '';
    }
  }

  private findAndSetView() {
    let outerArea = null;

    this.areasLayer.getSource().forEachFeature((f: Feature) => {
      const flags = f.getProperties()['flags'] as AreaFlag[]
      if (flags.includes(AreaFlag.CROP)) outerArea = f;
    })
    if (outerArea != null) {
      this.map.setView(new View({
        center: this.service.getCenterOfExtent(outerArea.getGeometry().getExtent()),
        zoom: 1,
        extent: buffer(outerArea.getGeometry().getExtent(), 100000),
        showFullExtent: true,
        enableRotation: false
      }));
      this.osm.addFilter(
        new Crop({feature: outerArea, wrapX: true, inner: false,})
      );
    }
  }

  zoomInToFeature() {
    let feature: Feature = null
    this.locationsLayer.getSource().forEachFeature(f => {
      if (feature == null  && f.getProperties()['labeled']) {
        feature = f;
      }
    });

    if (feature != null && this.map != null) {
      this.map.getView().fit(feature.getGeometry().getExtent(), {duration: 1000, maxZoom: 15})
      return;
    }

    this.areasLayer.getSource().forEachFeature(f => {
      if (feature == null && f.getProperties()['flags'] && (f.getProperties()['flags'] as AreaFlag[]).includes(AreaFlag.FOCUS)) {
        feature = f;
      }
    });

    if (feature != null && this.map != null) {
      this.map.getView().fit(feature.getGeometry().getExtent(), {duration: 1000, maxZoom: 17})
      return;
    }
  }

}
