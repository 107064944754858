import {EventEmitter, Injectable} from '@angular/core';
import {Area, Location} from "ng-geo-spatial-api";
import {Point, Polygon} from "ol/geom";
import {Feature} from "ol";
import {transform} from "ol/proj";
import {MapArea} from "./map-area.model";
import {MapLocation} from "./map-location.model";

@Injectable({
  providedIn: 'root'
})
export class MapService {
  areasEmitter: EventEmitter<MapArea[]> = new EventEmitter<MapArea[]>();
  areaIdFocusEmitter: EventEmitter<string> = new EventEmitter<string>();
  locationsEmitter: EventEmitter<MapLocation[]> = new EventEmitter<MapLocation[]>();
  locationIdFocusEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  getCenterOfExtent(Extent) {
    let X = Extent[0] + (Extent[2] - Extent[0]) / 2;
    let Y = Extent[1] + (Extent[3] - Extent[1]) / 2;
    return [X, Y];
  }

  mapAreaToFeature(area: MapArea) {
    let p = new Polygon(area.polygon.coordinates)
    p.transform('EPSG:4326', 'EPSG:3857');
    let f = new Feature({geometry: p})
    f.setProperties({
      id: area.id,
      type: area.type,
      name: area.name,
      route: area.route,
      flags:area.flags
    });
    return f
  }

  mapLocationToFeature(location: MapLocation) {
    let f = new Feature({
      geometry: new Point(transform(location.coordinates, 'EPSG:4326', 'EPSG:3857'))
    })
    f.setProperties({
      id: location.id,
      type: location.type,
      name: location.name,
      route: location.route,
      images: location.images,
      labeled: location.labeled
    });
    return f
  }
}
