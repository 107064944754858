export const environment = {
production: true,
  HOST: 'http://discovereazy.com',
  API_BASE_PATH: 'http://discovereazy.com',
  API_PROXY: "http://java-app-geo-spatial:8000/api",
  IMG_PATH: '/api/image/download/',
  REDIS_URL: "redis://default:redispw@redis:6379",

  ANALYTICS_MEASUREMENT_ID: "GT-MKB77TFS",
  ADSENSE_ID: "ca-pub-7738808715031445",
};
