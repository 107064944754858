<div class="card-container" matRipple [matRippleUnbounded]="false" [matRippleDisabled]="refRoute==null">
  <a *ngIf="refRoute!=null" [routerLink]="refRoute">
    <ng-container *ngIf="imageUrl!=null;then CardWithImage else CardWithoutImage"></ng-container>
  </a>
  <div *ngIf="refRoute==null">
    <ng-container *ngIf="imageUrl!=null;then CardWithImage else CardWithoutImage"></ng-container>
  </div>
</div>

<ng-template #CardWithoutImage>

  <div class="txt-info-section" fxLayout="column" fxLayoutAlign="center stretch">
    <div class="content" fxFlex>
      <h2 *ngIf="title!=''">{{ title }}</h2>
      <div [innerHTML]="content"></div>
    </div>
  </div>

</ng-template>

<ng-template #CardWithImage>

    <div class="info-section" fxLayout="row wrap" fxLayoutAlign="center stretch">
      <div class="img-container" fxFlex="100%">
        <div class="img" [ngStyle]="{'background-image': 'url('+imageUrl+')'}"></div>
      </div>
      <div class="content" fxFlex="100%">
        <h2 *ngIf="title!=''">{{ title }}</h2>
        <div [innerHTML]="content"></div>
      </div>
    </div>

</ng-template>
