import {Component, Input, OnInit} from '@angular/core';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatRipple} from "@angular/material/core";
import {NgIf, NgStyle} from "@angular/common";
import {RouterLink} from "@angular/router";
import {Area, AreaInfoSection, LocInfoSection} from "ng-geo-spatial-api";
import {environment} from "../../../../environments/environment";
import {MediaObserver} from '@angular/flex-layout';
import {FlexLayoutServerModule} from "@angular/flex-layout/server";

@Component({
  selector: 'app-info-section',
  standalone: true,
  imports: [FlexLayoutModule, FlexLayoutServerModule, MatRipple, NgIf, RouterLink, NgStyle],
  templateUrl: './info-section.component.html',
  styleUrl: './info-section.component.scss'
})
export class InfoSectionComponent implements OnInit {
  @Input() order: number;
  @Input() areaInfoSection: AreaInfoSection
  @Input() locInfoSection: LocInfoSection
  @Input() areas: Area[] = [];

  imgBasePath = environment.API_BASE_PATH + environment.IMG_PATH;
  imageUrl: string = null;
  title: string = "";
  content: string = "";
  refRoute: string = null;

  constructor(public media: MediaObserver) {
  }

  ngOnInit(): void {

    if (this.areaInfoSection != null) {
      this.initBasedOnAreaInfo()
    } else if (this.locInfoSection != null) {
      this.initBasedOnLocInfo()
    } else {
      this.title = ""
      this.content = ""
      this.refRoute = null
    }
  }

  private initBasedOnAreaInfo() {
    if (this.areaInfoSection.location != null) {
      this.refRoute = this.areaInfoSection.location.route
      if (this.areaInfoSection.location.images.length > 0) {
        this.imageUrl = this.imgBasePath + this.areaInfoSection.location.images[0].medium.name;
      }
    }
    this.title = this.areaInfoSection.title;
    this.content = this.areaInfoSection.content;
  }

  private initBasedOnLocInfo() {
    if (this.locInfoSection.image != null) {
      this.imageUrl = this.imgBasePath + this.locInfoSection.image.medium.name;
    }
    this.title = this.locInfoSection.title;
    this.content = this.locInfoSection.content;
  }

}
